import axios from "axios";
import React from "react"
/*
  inteex.js -> redirects user to canvas page
  theme.liquid -> adds design to the cart
  main-cart.liquid -> displays Customization in the cart
*/

async function uploadImage(file) {
  const formData = new FormData();
  formData.append("image", file);

  const response = await axios.post(
    "https://dtf-dallas-3c549f16377b.herokuapp.com/api/upload-image/",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return [response.data.url, response.data.checksum];
}


function selectKeysFromArrayObjects(array, keys) {
  return array.map(item => 
    keys.reduce((obj, key) => {
      if (item.hasOwnProperty(key)) {
        obj[key] = item[key];
      }
      return obj;
    }, {})
  );
}



const saveCanvasStateToDB = async (
  design_id,
  variant_id,
  canvas_data,
  uploaded_images_data,
  email = "",
  status = "draft"
) => {
  const canvas_img_keys = ["id", "checksum", "height", "initialHeight", "initialWidth", "isSvg", "src", "rotation", "width", "x", "y"]
  const canvas_data_arr = selectKeysFromArrayObjects(canvas_data, canvas_img_keys);

  const uploaded_img_keys = ["fileId", "checksum", "height", "isSvg", "src", "width", "count"];
  const uploaded_images_data_arr = selectKeysFromArrayObjects(uploaded_images_data, uploaded_img_keys);

  const params = {
    design_id: design_id,
    variant_id: variant_id,
    canvas_data: canvas_data_arr,
    uploaded_images_data: uploaded_images_data_arr,
    status: status,
    email: email,
  };

  await axios.post("https://dtf-dallas-3c549f16377b.herokuapp.com/api/save-canvas/", params);
};



const getCanvasStateFromDB = async (design_id) => {
  try {
    const params = {
      design_id: design_id,
    };
    const response = await axios.post(
      "https://dtf-dallas-3c549f16377b.herokuapp.com/api/get-canvas/",
      params
    );
  
    const data = response.data.data;
    const PPI = 50.8;
  
    const canvas_images = data.canvas_data.map((obj) => ({
      ...obj,
      nodeRef: React.createRef(),
      showWarningIcon: false,
      warningIconColor: "orange"
    }));
  
    const uploaded_images = data.uploaded_image_data.map((obj) => ({
      ...obj,
      printWidth: obj.isSvg ? 200
                            : (obj.width * PPI) / 310,
      printHeight: obj.isSvg ? 200
                            : (obj.height * PPI) / 310,
      progress: 100,
      loaded: true,
    }));
  
    return [canvas_images, uploaded_images, data.status];
  }
  catch(e) {
    return [null, null, null];
  }
};

const getDesignIDFromDB = async () => {
  const response = await axios.get("https://dtf-dallas-3c549f16377b.herokuapp.com/api/create-uuid/");

  const data = response.data.uuid;
  return data;
};

export { saveCanvasStateToDB, getCanvasStateFromDB, getDesignIDFromDB, uploadImage };
