import { Image, Text, Circle } from "react-konva";
import CustomTransformer from "./CustomTransformer"
import React, { useRef } from "react";

import useImage from "use-image";

const CanvasImage = React.memo(
  ({
    imageProps,
    isSelected,
    onSelect,
    onChange,
    onSelectedImagePropsChange,
    onTransformEnd,
    onDragEnd,
    onCanvasChange,
    designStatus
  }) => {
    
    const [img] = useImage(imageProps.src, 'anonymous');
    const initialDimensions = useRef({ width: 0, height: 0 });

    // handleOnTransform and handleOnDragMove are written to display the image changes on the screen immediately! (Image will be resized or transformed even if these functions do not exist but writing these values on the screen requires them.)
    const handleOnTransform = (e) => {
      const node = e.target;
      const rotation = node.rotation();

      if (rotation.toFixed(2) !== imageProps.rotation.toFixed(2)) {
        //console.log(`New rotation: ${rotation.toFixed(2)} Old rotation: ${imageProps.rotation.toFixed(2)}`)
        const newProps = {
          x: node.x(),
          y: node.y(),
          width: node.width(),
          height: node.height(),
          rotation: parseFloat(rotation.toFixed(2)),
        };

        onChange(newProps);
        onSelectedImagePropsChange((prevProps) => ({
          ...prevProps,
          newProps,
        }));

        return;
      }

      const scaleX = node.scaleX();
      const scaleY = node.scaleY();

      //console.log(`scaleX: ${scaleX} scaleY: ${scaleY}`)

      const newWidth = Math.max(5, node.width() * scaleX);
      const newHeight = Math.max(5, node.height() * scaleY);

      const newProps = {
        x: node.x(),
        y: node.y(),
        width: newWidth,
        height: newHeight,
        isSelected: true,
        rotation: parseFloat(rotation.toFixed(2)),
      };

      onChange(newProps); // OnChange is necessary to save the updates.
      onSelectedImagePropsChange((prevProps) => ({
        ...prevProps,
        newProps,
      }));
    };

    const handleOnTransformStart = () => {
      onCanvasChange();
      const node = imageProps.nodeRef.current;
      initialDimensions.current = {
        width: node.width(),
        height: node.height(),
      };
    };

    const handleDragStart = () => {
      onCanvasChange();
    };

    const handleOnTransformEnd = (e) => {
      const node = e.target;
      const width = node.width();
      const height = node.height();

      onTransformEnd({
        initialWidth: initialDimensions.current.width,
        initialHeight: initialDimensions.current.height,
        finalWidth: width,
        finalHeight: height,
      });
    };

    const handleOnDragMove = (e) => {
      const node = e.target;
      const rotation = node.rotation();
      node.setAttrs({ // This one prevents image to become larger while rotating the image
        scaleX: 1,
        scaleY: 1,
      });
      const newProps = {
        x: node.x(),
        y: node.y(),
        width: node.width(),
        height: node.height(),
        rotation: rotation,
      };

      onChange(newProps);
      onSelectedImagePropsChange((prevProps) => ({
        ...prevProps,
        newProps,
        isSelected: true,
      }));
    };


    const handleOnDragEnd = (e) => {
      handleOnDragMove(e);
      onDragEnd();
    }

    return (
      <>
        <Image
          onClick={designStatus !== "completed" ? onSelect : null}
          {...imageProps}
          ref={imageProps.nodeRef}
          image={img}
          draggable={designStatus !== "completed"} 
          onDragStart={designStatus !== "completed" ? handleDragStart : null}
          onDragEnd={designStatus !== "completed" ? handleOnDragEnd : null}
          onTransformStart={designStatus !== "completed" ? handleOnTransformStart : null}
          onTransformEnd={designStatus !== "completed" ? handleOnTransformEnd : null}
          onTransform={designStatus !== "completed" ? handleOnTransform : null}
          onDragMove={designStatus !== "completed" ? handleOnDragMove : null}
          cache
        />
        {imageProps.showWarningIcon && (
          <>
            <Circle
              x={imageProps.x + imageProps.width / 2 * Math.cos(imageProps.rotation * Math.PI / 180) - imageProps.height / 2 * Math.sin(imageProps.rotation * Math.PI / 180)}
              y={imageProps.y + imageProps.width / 2 * Math.sin(imageProps.rotation * Math.PI / 180) + imageProps.height / 2 * Math.cos(imageProps.rotation * Math.PI / 180)}
              radius={20}
              fill={imageProps.warningIconColor}
              listening={false}
            />
            <Text
              x={imageProps.x + imageProps.width / 2 * Math.cos(imageProps.rotation * Math.PI / 180) - imageProps.height / 2 * Math.sin(imageProps.rotation * Math.PI / 180) - 5}
              y={imageProps.y + imageProps.width / 2 * Math.sin(imageProps.rotation * Math.PI / 180) + imageProps.height / 2 * Math.cos(imageProps.rotation * Math.PI / 180) - 15}
              text="!"
              fontSize={25}
              fontFamily="Arial"
              fill="white"
              listening={false}
            />
          </>
        )}

        {isSelected && (
          <>
            <CustomTransformer img={img} imageProps={imageProps} isSelected={isSelected} />
          </>
        )}
      </>
    );
  }
);

export default CanvasImage;
