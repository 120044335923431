import React from 'react'

const CanvasHorizontalRuler = React.memo(({ widthInInches, PPI, scale, stageXPos }) => {
    // Create an array to hold the JSX for marks and labels
    const elements = [];
    const scaledPPI = PPI * scale;
  
    for (let i = 0; i <= widthInInches; i++) {
      // Push mark elements into the array
      elements.push(
        <div
          style={{
            left: `${i * scaledPPI}px`,
            position: "absolute",
            top: "8px",
            fontWeight: "normal",
          }}
        >
          <div
            key={`hor_label_${i}`}
            style={{
              fontSize: "10px",
            }}
          >
            {i}
          </div>
          <div
            key={`hor_mark_${i}`}
            style={{
              bottom: "0px",
              width: "2px",
              height: "8px",
              backgroundColor: "black",
            }}
          />
        </div>
      );
  
      const distance = scaledPPI / 5;
      if (i !== widthInInches) {
        for (let j = 1; j < 5; j++) {
          elements.push(
            <div
              key={`hor_sub_mark_${i * 5 + j}`}
              style={{
                left: `${i * scaledPPI + distance * j}px`,
                bottom: "0px",
                width: "1px",
                height: "6px",
                position: "absolute",
                backgroundColor: "black",
              }}
            />
          );
        }
      }
    }
  
    // Return a container with all ruler elements
    return (
      <div
        style={{
          display: "flex",
          boxSizing: "border-box",
          left: stageXPos + 30,
          height: "30px",
          position: "relative", // Container is relative; children are absolute
          background: "#a0b6e8",
        }}
      >
        {elements}
      </div>
    );
  })

  export default CanvasHorizontalRuler;