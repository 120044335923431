import React from 'react'

const CanvasVerticalRuler = React.memo(({ widthInInches, PPI, scale, stageYPos }) => {
    // Create an array to hold the JSX for marks and labels
    const elements = [];
    const scaledPPI = PPI * scale;
  
    for (let i = 0; i <= widthInInches; i++) {
      // Push mark elements into the array
      elements.push(
        <div
          style={{
            top: `${i * scaledPPI}px`,
            position: "absolute",
            left: "8px",
            fontWeight: "normal",
            writingMode: "vertical-lr",
          }}
        >
          <div
            key={`ver_label_${i}`}
            style={{
              fontSize: "10px",
            }}
          >
            {i}
          </div>
          <div
            key={`ver_mark_${i}`}
            style={{
              bottom: "0px",
              height: "2px",
              width: "8px",
              backgroundColor: "black",
            }}
          />
        </div>
      );
  
      const distance = scaledPPI / 5;
      if (i !== widthInInches) {
        for (let j = 1; j < 5; j++) {
          elements.push(
            <div
              key={`ver_sub_mark_${i * 5 + j}`}
              style={{
                top: `${i * scaledPPI + distance * j}px`,
                right: "0px",
                height: "1px",
                width: "6px",
                position: "absolute",
                backgroundColor: "black",
              }}
            />
          );
        }
      }
    }
  
    return (
      <div
        style={{
          display: "flex",
          boxSizing: "border-box",
          top: stageYPos,
          width: "30px",
          position: "relative",
          background: "#a0b6e8",
        }}
      >
        {elements}
      </div>
    );
  });
  
export default CanvasVerticalRuler;  