import { Transformer } from "react-konva";
import React, { useRef, useEffect } from "react";

const CustomTransformer = ({ img, imageProps, isSelected }) => {
  const trRef = useRef();

  useEffect(() => {
    if (img) {
      if (isSelected && imageProps.nodeRef) {
        trRef.current.nodes([imageProps.nodeRef.current]);
        trRef.current.getLayer().batchDraw();
      }
    }
  }, [isSelected, img, imageProps.nodeRef]);

  return (
    <Transformer
      ref={trRef}
      anchorSize={10}
      anchorFill="#00aaff"
      anchorCornerRadius={5}
      borderStroke="#00aaff"
      borderStrokeWidth={1}
      anchorStroke="#000"
      anchorStrokeWidth={1}
    />
  );
};

export default CustomTransformer;
