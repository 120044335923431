import * as React from "react";
import Drawer from "@mui/joy/Drawer";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import ModalClose from "@mui/joy/ModalClose";
import Divider from "@mui/joy/Divider";
import Stack from "@mui/joy/Stack";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import FormLabel from "@mui/joy/FormLabel";
import { useState, useEffect } from "react";
import { saveCanvasStateToDB } from "../connections/db";
import { sendToShopify, getVariantId } from "../connections/shopify"; 
import {CircularProgress} from "@mui/material";

const SideBar = ({
  open,
  setOpen,
  canvasImages,
  uploadedImages,
  queryParams,
  updateUnsavedChanges
}) => {
  const [overlappingImgCount, setOverlappingImgCount] = useState(0);
  const [lessDpiImgCount, setLessDpiImgCount] = useState(0);
  const [leastDpiImgCount, setLeastDpiImgCount] = useState(0);
  const [outsideOfCanvasImgCount, setOutsideOfCanvasImgCount] = useState(0);
  const [email, setEmail] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false)

  useEffect(() => {
    setOverlappingImgCount(
      canvasImages.filter(
        (img) => img.showWarningIcon === true && img.warningIconColor === "blue"
      ).length
    );
    setLessDpiImgCount(
      canvasImages.filter(
        (img) =>
          img.showWarningIcon === true && img.warningIconColor === "orange"
      ).length
    );
    setLeastDpiImgCount(
      canvasImages.filter(
        (img) => img.showWarningIcon === true && img.warningIconColor === "red"
      ).length
    );
    setOutsideOfCanvasImgCount(
      canvasImages.filter(
        (img) =>
          img.showWarningIcon === true && img.warningIconColor === "purple"
      ).length
    );
  }, [canvasImages]);


  const handleProceed = async () => {
    setButtonLoading(true);
    const cloneImages = canvasImages.map((a) => ({ ...a }));
    const cloneUploadedImages = uploadedImages.map((a) => ({ ...a }));
    const id = queryParams.get("id");
    const canvasSize = queryParams.get("canvas");
    const variant = getVariantId(canvasSize);
    updateUnsavedChanges(false);
    await saveCanvasStateToDB(id, variant, cloneImages, cloneUploadedImages, email);
    const drawingData = {
      canvasURL:  window.location.href,
      variant: variant
    };
    setButtonLoading(false);
    sendToShopify(drawingData);
  };

  return (
    <React.Fragment>
      <Drawer
        size="md"
        variant="plain"
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              p: { md: 3, sm: 0 },
              boxShadow: "none",
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: "md",
            p: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <DialogTitle>Do you want to proceed?</DialogTitle>
          <ModalClose />
          <Divider sx={{ mt: "auto" }} />
          <DialogContent sx={{ gap: 2 }}>
            <Typography level="title-md" fontWeight="bold" sx={{ mt: 1 }}>
              Notes
            </Typography>

            <FormLabel
              sx={{
                display:
                  canvasImages.length > 0 &&
                  leastDpiImgCount === 0 &&
                  lessDpiImgCount === 0 &&
                  overlappingImgCount === 0 &&
                  outsideOfCanvasImgCount === 0
                    ? "block"
                    : "none",
              }}
            >
              Your canvas is good to go!
            </FormLabel>

            <FormLabel
              sx={{
                color: "red",
                display: canvasImages.length === 0 ? "block" : "none",
              }}
            >
              Canvas does not have any images!
            </FormLabel>

            <FormLabel
              sx={{ mt: 1, display: leastDpiImgCount > 0 ? "block" : "none" }}
            >
              Your canvas contains {leastDpiImgCount} images with a resolution
              below 250 DPI. Please review the images highlighted with{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>red </span>
              borders to identify them.
            </FormLabel>

            <FormLabel
              sx={{ mt: 1, display: lessDpiImgCount > 0 ? "block" : "none" }}
            >
              Your canvas contains {lessDpiImgCount} images with a resolution
              below 350 DPI. Please review the images highlighted with{" "}
              <span style={{ color: "orange", fontWeight: "bold" }}>
                orange{" "}
              </span>
              borders to identify them.
            </FormLabel>

            <FormLabel
              sx={{
                mt: 1,
                display: overlappingImgCount > 0 ? "block" : "none",
              }}
            >
              Your canvas contains {overlappingImgCount} overlapping images.
              Please review the images highlighted with{" "}
              <span style={{ color: "blue", fontWeight: "bold" }}>blue </span>
              borders to identify them.
            </FormLabel>

            <FormLabel
              sx={{
                mt: 1,
                display: outsideOfCanvasImgCount > 0 ? "block" : "none",
              }}
            >
              Your canvas contains {outsideOfCanvasImgCount} images that extend
              beyond its boundaries. Please review the images highlighted with{" "}
              <span style={{ color: "purple", fontWeight: "bold" }}>
                purple{" "}
              </span>
              borders to identify them.
            </FormLabel>
          </DialogContent>

          <Divider sx={{ mt: "auto" }} />
          <FormLabel>Email:</FormLabel>
          <Input placeholder="Please enter your email" required value={email} onChange={(e) => setEmail(e.target.value)}/>
          <Stack
            direction="row"
            justifyContent="space-between"
            useFlexGap
            spacing={1}
          >
            <Button
              variant="outlined"
              color="neutral"
              onClick={() => setOpen(false)}
            >
              Back
            </Button>
            <Button
              onClick={handleProceed}
              style={{
                background: "green",
                cursor: canvasImages.length === 0 ? "not-allowed" : "pointer",
              }}
              disabled={canvasImages.length === 0 || !email.includes("@") || buttonLoading}
            >
              {buttonLoading && (
                <CircularProgress size={25} style={{marginRight: "5px"}}/>
              )}
              Proceed
            </Button>
          </Stack>
        </Sheet>
      </Drawer>
    </React.Fragment>
  );
};

export { SideBar };
