import React, {
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";

import { Stage, Layer, Rect } from "react-konva";

import CanvasImage from "./CanvasImage";

const KonvaCanvas = React.memo(
  forwardRef(
    (
      {
        designStatus,
        scale,
        width,
        height,
        images,
        onSelectedImagePropsChange,
        onImagesChange,
        onStagePosChange,
        onImageDelete,
        canvasHasBackgrounds,
        onImageTransformEnd,
        onImageDragEnd,
        selectedImageProps,
        onCanvasChange,
      },
      ref
    ) => {
      const stageRef = useRef(null);
      const layerRef = useRef(null);

      useEffect(() => {
        const stage = stageRef.current.getStage();
        stage.scale({ x: scale, y: scale }); // Apply the scale to the stage
        centerStage(scale);
        onStagePosChange(stage.x(), stage.y());
        stage.batchDraw();
      }, [onStagePosChange, scale]);

      const centerStage = (newScale) => {
        const stage = stageRef.current.getStage();
        const stageWidth = stage.width();

        const containerWidth = stageWidth;

        const newWidth = stageWidth * newScale;

        const offsetX = (containerWidth - newWidth) / 2;

        if (newWidth < containerWidth) {
          stage.x(offsetX);
        } else {
          stage.x(0);
        }

        stage.batchDraw();
      };

      useImperativeHandle(ref, () => ({
        getStageDataURL() {
          const stage = stageRef.current.getStage();
          stage.scale({ x: 1, y: 1 });

          stage.position({
            x: 0,
            y: 0,
          });

          return stageRef.current.toDataURL({
            pixelRatio: 3,
            quality: 1,
          });
        },
        getLayerRef() {
          return layerRef;
        },
        getStage() {
          return stageRef.current.getStage();
        },
      }));

      const handleSelect = (id) => {
        const selectedImage = images.filter((img) => img.id === id)[0];

        onSelectedImagePropsChange({
          id: selectedImage.id,
          x: selectedImage.x,
          y: selectedImage.y,
          width: selectedImage.width,
          height: selectedImage.height,
          rotation: selectedImage.rotation,
          isSelected: true,
        });
      };

      const handleTransform = (newAttrs, id) => {
        // This function will save the changes on images!
        const updatedImages = images.slice();
        const updatedImg = updatedImages.filter((img) => img.id === id)[0];
        const idx = updatedImages.indexOf(updatedImg);
        updatedImages[idx].x = newAttrs.x;
        updatedImages[idx].width = newAttrs.width;
        updatedImages[idx].y = newAttrs.y;
        updatedImages[idx].height = newAttrs.height;
        updatedImages[idx].rotation = newAttrs.rotation;

        onImagesChange(updatedImages);
        onSelectedImagePropsChange({
          id: updatedImg.id,
          x: updatedImg.x,
          y: updatedImg.y,
          width: updatedImg.width,
          height: updatedImg.height,
          rotation: updatedImg.rotation,
          isSelected: true,
        });
      };

      const handleStageClick = (e) => {
        // Check if the clicked target is a part of the stage but not an image
        if (e.target === e.target.getStage()) {
          onSelectedImagePropsChange((prevProps) => ({
            ...prevProps,
            id: null,
            isSelected: false,
          }));
        }
      };

      useEffect(() => {
        const handleDelete = () => {
          if (selectedImageProps.id !== null) {
            onImageDelete();
          }
        };

        const handleKeyDown = (e) => {
          if (e.key === "Delete") {
            handleDelete();
          }
        };

        // Attach the event listener
        window.addEventListener("keydown", handleKeyDown);

        return () => {
          window.removeEventListener("keydown", handleKeyDown);
        };
      }, [
        selectedImageProps,
        images,
        onImagesChange,
        onSelectedImagePropsChange,
        onImageDelete,
      ]);

      return (
        <>
          <Stage
            ref={stageRef}
            width={parseInt(width)}
            height={parseInt(height)}
            scale={{ x: scale, y: scale }}
            onClick={handleStageClick}
            y={100}
          >
            <Layer ref={layerRef}>
              {canvasHasBackgrounds && (
                <CheckeredRect width={width} height={height} />
              )}
              {images.map((img) => (
                <CanvasImage
                  key={img.id}
                  imageProps={{ ...img }}
                  isSelected={
                    selectedImageProps &&
                    selectedImageProps.isSelected &&
                    img.id === selectedImageProps.id &&
                    canvasHasBackgrounds
                  }
                  onSelect={() => handleSelect(img.id)}
                  onChange={(newAttrs) => handleTransform(newAttrs, img.id)}
                  onSelectedImagePropsChange={onSelectedImagePropsChange}
                  onTransformEnd={onImageTransformEnd}
                  onDragEnd={onImageDragEnd}
                  onCanvasChange={onCanvasChange}
                  designStatus={designStatus}
                />
              ))}
            </Layer>
          </Stage>
        </>
      );
    }
  )
);

const CheckeredRect = ({ width, height }) => {
  const [patternImage, setPatternImage] = useState(null);

  useEffect(() => {
    const patternCanvas = createCheckeredPattern(10, width, height);
    setPatternImage(patternCanvas);
  }, [width, height]);

  return (
    <Rect
      x={0}
      y={0}
      width={width}
      height={height}
      fillPatternImage={patternImage}
      listening={false}
    />
  );
};

const createCheckeredPattern = (patternSize = 10, width, height) => {
  const patternCanvas = document.createElement("canvas");
  patternCanvas.width = width;
  patternCanvas.height = height;
  const context = patternCanvas.getContext("2d");

  for (let x = 0; x < width; x += patternSize) {
    for (let y = 0; y < height; y += patternSize) {
      context.fillStyle =
        (x / patternSize + y / patternSize) % 2 === 0 ? "white" : "#CCCCCC";
      context.fillRect(x, y, patternSize, patternSize);
    }
  }

  // top-border
  context.beginPath();
  context.setLineDash([5, 3]);
  context.lineWidth = 3;
  context.strokeStyle = "purple";
  context.moveTo(patternSize, patternSize);
  context.lineTo(width - patternSize, patternSize);
  context.stroke();

  // right-border
  context.beginPath();
  context.setLineDash([5, 3]);
  context.lineWidth = 3;
  context.strokeStyle = "purple";
  context.moveTo(width - patternSize, patternSize);
  context.lineTo(width - patternSize, height - patternSize);
  context.stroke();

  // left-border
  context.beginPath();
  context.setLineDash([5, 3]);
  context.lineWidth = 3;
  context.strokeStyle = "purple";
  context.moveTo(patternSize, patternSize);
  context.lineTo(patternSize, height - patternSize);
  context.stroke();

  // bottom-border
  context.beginPath();
  context.setLineDash([5, 3]);
  context.lineWidth = 3;
  context.strokeStyle = "purple";
  context.moveTo(patternSize, height - patternSize);
  context.lineTo(width - patternSize, height - patternSize);
  context.stroke();
    
  

  return patternCanvas;
};

export default KonvaCanvas;
