
function sendToShopify(drawingData) {
  const drawingDataEncoded = encodeURIComponent(JSON.stringify(drawingData));
  window.location.href = `https://quickstart-e6c2dfe7.myshopify.com/cart?drawingData=${drawingDataEncoded}`;
}


const getVariantId = (canvasSize) => {
    switch(canvasSize) {
        case "22x20":
            return 48896987791657;
        case "22x60":
            return 48896987824425;
        case "22x80":
            return 48896987857193;
        case "22x100":
            return 48896987889961;
        case "22x120":
            return 48896987922729;
        case "22x150":
            return 48896987955497;
        case "22x200":
            return 48896987988265;
        default:
            return 48896987791657;
    }
}

/*
dtfdallas
const getVariantId = (canvasSize) => {
    switch(canvasSize) {
        case "22x20":
            return 48859406958880;
        case "22x60":
            return 48859406991648;
        case "22x80":
            return 48859407024416;
        case "22x100":
            return 48859407057184;
        case "22x120":
            return 48859407089952;
        case "22x150":
            return 48859407122720;
        case "22x200":
            return 48859407155488;
        default:
            return 48859406958880;
    }
}*/

export {getVariantId, sendToShopify};

