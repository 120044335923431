import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import {
  TextField,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  IconButton,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Text } from "react-konva";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import logo from "./images/logoyatay.png";
import { useLocation, useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "./App.css";
import KonvaCanvas from "./components/KonvaCanvas";
import CanvasHorizontalRuler from "./components/HorizontalRuler";
import CanvasVerticalRuler from "./components/VerticalRuler";
import { SideBar } from "./components/SideBar";
import { ModalDialog, Modal } from "@mui/joy";
import {
  saveCanvasStateToDB,
  getCanvasStateFromDB,
  getDesignIDFromDB,
  uploadImage,
} from "./connections/db";
import { getVariantId } from "./connections/shopify";
import NotFoundPage from "./components/404";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PPI = 50.8;
const widthInInches = 22;
function App() {
  const [images, setImages] = useState([]);
  const imagesRef = useRef(images);
  const [canvasImages, setCanvasImages] = useState([]);
  const canvasImagesRef = useRef(canvasImages);
  const [imgMargin, setImgMargin] = useState(0.5);
  const [stageScale, setStageScale] = useState(0.6);
  const [stagePositions, setStagePositions] = useState({ x: 0, y: 0 });
  const location = useLocation();
  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const canvasRef = useRef(null);
  const [notFound, setNotFound] = useState(false);
  const [initialWidth, setInitialWidth] = useState(0);
  const [initialHeight, setInitialHeight] = useState(0);
  const [heightInInches, setHeightInInches] = useState(0);

  const [canvasHasBackgrounds, setCanvasHasBackgrounds] = useState(true);

  const [openSideBar, setOpenSideBar] = useState(false);

  const [openLoadingDialog, setOpenLoadingDialog] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [openDPIDialog, setOpenDPIDialog] = useState(false);

  const [currentImgDPI, setCurrentImgDPI] = useState(0);

  const [imgManualWidth, setImgManualWidth] = useState(0);
  const [imgManualHeight, setImgManualHeight] = useState(0);

  const [designid, setDesignId] = useState("");
  const [variantId, setVariantId] = useState("");
  const [designStatus, setDesignStatus] = useState("draft");

  const requestRef = useRef(); // Using requestRef is the best practice when we want to stop the animation upon component unmounting or under certain conditions
  const navigate = useNavigate();

  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  const [zoomInDisabled, setZoomInDisabled] = useState(false);

  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [openSaveSnackbar, setOpenSaveSnackbar] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const [selectedImageProps, setSelectedImageProps] = useState({
    id: 0,
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    rotation: 0,
    isSelected: false,
  });

  useEffect(() => {
    imagesRef.current = images;
  }, [images]);

  useEffect(() => {
    canvasImagesRef.current = canvasImages;
  }, [canvasImages]);

  const handleDPIDialogClose = () => {
    setOpenDPIDialog(false);
  };

  useEffect(() => {
    const loadCanvasData = async () => {
      try {
        let canvas = queryParams.get("canvas");
        if (!canvas) {
          canvas = "22x20";
        }
        let heightInInch = 20;
        switch (canvas) {
          case "22x20":
            heightInInch = 20;
            break;
          case "22x60":
            heightInInch = 60;
            break;
          case "22x80":
            heightInInch = 80;
            break;
          case "22x100":
            heightInInch = 100;
            break;
          case "22x120":
            heightInInch = 120;
            break;
          case "22x150":
            heightInInch = 150;
            break;
          case "22x200":
            heightInInch = 200;
            break;
          default:
            heightInInch = 20;
        }

        setInitialWidth(22 * PPI);
        setInitialHeight(heightInInch * PPI);
        setHeightInInches(heightInInch);

        let params = `?canvas=${canvas}`;
        let id = queryParams.get("id");
        if (!id) {
          const generatedId = await getDesignIDFromDB();
          if (generatedId) {
            params += `&id=${generatedId}`;
            setDesignId(generatedId);
            navigate(params, { replace: true });
          }
        } else {
          setDesignId(id);
          const [canvas_images, uploaded_images, status] =
            await getCanvasStateFromDB(id);
          if (status) {
            setImages([...uploaded_images]);
            setDesignStatus(status);
            setCanvasImages([...canvas_images]);
            updateBorders(canvas_images, 22 * PPI, heightInInch * PPI);
          } else {
            setNotFound(true);
          }
        }

        const variant = getVariantId(canvas);
        setVariantId(variant);
      } catch (error) {
        console.error("Error loading canvas data:", error);
      } finally {
        setOpenLoadingDialog(false);
        setIsLoading(false);
      }
    };

    loadCanvasData();
  }, []);

  useEffect(() => {
    if (canvasImages.length > 0) {
      requestRef.current = requestAnimationFrame(animateBorders);
    }
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current);
      }
    };
  }, [canvasImages]);

  const saveCanvasState = () => {
    const cloneImages = canvasImages.map((a) => ({ ...a }));
    setUndoStack([...undoStack, cloneImages]);
    setRedoStack([]);
  };


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (unsavedChanges) {
        // Cancel the event
        event.preventDefault(); 
        // Chrome requires returnValue to be set
        event.returnValue = '';
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [unsavedChanges]);
  
  const handleUnsavedChange = (newState) => {
    setUnsavedChanges(newState);
  }

  const saveCanvasToDB = async (cImages, uImages) => {
    setSaveButtonLoading(true);
    await saveCanvasStateToDB(designid, variantId, cImages, uImages);
    setUnsavedChanges(false);
    setSaveButtonLoading(false);
    setOpenSaveSnackbar(true);
    setTimeout(() => {
      setOpenSaveSnackbar(false);
    }, 3000);
  };

  const handleUndo = () => {
    if (undoStack.length === 0) return;

    const previousState = undoStack.pop();
    setRedoStack([...redoStack, canvasImages]);
    setCanvasImages(previousState);
    updateImagesCount(previousState);

    setUnsavedChanges(true);
    //saveCanvasToDB(previousState, images);
  };

  const handleRedo = () => {
    if (redoStack.length === 0) return;

    const nextState = redoStack.pop();
    setUndoStack([...undoStack, canvasImages]);
    setCanvasImages(nextState);
    updateImagesCount(nextState);

    setUnsavedChanges(true);
    //saveCanvasToDB(nextState, images);
  };

  const zoomIn = () => {
    const newScale = stageScale * 1.05;

    const { scaledWidth, scaledHeight } = calculateScaledDimensions(
      initialWidth,
      initialHeight,
      newScale
    );

    if (
      scaledWidth < initialWidth - 150 &&
      scaledHeight < initialHeight - 150
    ) {
      setStageScale(newScale);
    } else {
      setZoomInDisabled(true);
    }
    setStageScale(newScale);
  };

  const zoomOut = () => {
    const newScale = stageScale / 1.05; // Decrease scale by 5%

    setStageScale(newScale);
    setZoomInDisabled(false);
  };

  const handleSelectedImagePropsChange = (props) => {
    setSelectedImageProps(props);
    updateBorders(canvasImages);

    if (props.id) {
      setImgManualHeight((props.height / PPI).toFixed(2));
      setImgManualWidth((props.width / PPI).toFixed(2));
    }
  };

  const handleStagePosChange = useCallback((xPos, yPos) => {
    setStagePositions({ x: xPos, y: yPos });
  }, []);

  const handleImagesChange = (newImages) => {
    setCanvasImages(newImages);
    images.forEach((image) => {
      const src = image.src;
      const uploadedCanvasImgs = newImages.filter((item) => item.src === src);
      image.count = uploadedCanvasImgs.length;
    });
  };

  const handleRemoveBackground = () => {
    setCanvasHasBackgrounds(false);
    if (requestRef.current) {
      cancelAnimationFrame(requestRef.current);
    }

    const updatedCanvasImages = canvasImages.map((image) => ({
      ...image,
      showWarningIcon: false,
      strokeWidth: 0,
    }));

    setCanvasImages([...updatedCanvasImages]);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    Array.from(files).forEach((file, index) => {
      if (
        file &&
        (file.type === "image/png" ||
          file.type === "image/jpeg" ||
          file.type === "image/jpg" ||
          file.type === "image/svg+xml")
      ) {
        const url = URL.createObjectURL(file);
        const fileId = file.name + "_" + file.size;
        const initialImageInfo = {
          url,
          loaded: false,
          fileId,
        };

        if (imagesRef.current.some((image) => image.fileId === fileId)) {
          alert("This image is already uploaded.");
          return; // Skip uploading duplicate image
        }

        setImages((prevImages) => [...prevImages, initialImageInfo]); // Add here to display preview

        const reader = new FileReader();

        reader.onload = (event) => {
          const img = new window.Image();
          img.onload = async () => {
            let isSvg = file.type === "image/svg+xml";
            const [url, checksum] = await uploadImage(file);
            setImages((prevImages) =>
              prevImages.map(
                (
                  image // update the image once it is loaded
                ) =>
                  image.fileId === fileId
                    ? {
                        ...image,
                        width: img.width,
                        fileId: fileId,
                        height: img.height,
                        loaded: true,
                        src: url,
                        checksum: checksum,
                        isSvg: isSvg,
                        printWidth: isSvg
                          ? 200
                          : calculatePrintWidth(img.width),
                        printHeight: isSvg
                          ? 200
                          : calculatePrintHeight(img.height),
                        count: canvasImages.filter(
                          (item) => item.src === event.target.result
                        ).length,
                      }
                    : image
              )
            );
          };

          img.src = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Please upload a PNG, JPG, JPEG, or SVG file.");
      }
    });

    event.target.value = null;
  };

  function calculateDPI(
    pixelWidth,
    pixelHeight,
    printWidthInches,
    printHeightInches
  ) {
    const dpiWidth = pixelWidth / printWidthInches;
    const dpiHeight = pixelHeight / printHeightInches;
    return {
      dpiWidth,
      dpiHeight,
      averageDPI: (dpiWidth + dpiHeight) / 2,
    };
  }

  function calculatePrintWidth(pixelWidth) {
    return (pixelWidth * PPI) / 310;
  }

  function calculatePrintHeight(pixelHeight) {
    return (pixelHeight * PPI) / 310;
  }

  const getImageCorners = (x, y, width, height, rotationDegree) => {
    const radians = (Math.PI / 180) * rotationDegree;

    return [
      { x: x, y: y }, // Top-left corner
      { x: x - height * Math.sin(radians), y: y + height * Math.cos(radians) }, // Bottom-left corner
      {
        x: x + width * Math.cos(radians) - height * Math.sin(radians),
        y: y + width * Math.sin(radians) + height * Math.cos(radians),
      }, // Bottom-right corner
      { x: x + width * Math.cos(radians), y: y + width * Math.sin(radians) }, // Top-right corner
    ];
  };

  function pointInLeftHandSide(x2, y2, x1, y1, xp, yp) {
    // checks if the point (xp, yp) is in the left hand side of the edge (x1, y1) - (x2, y2)
    const D = (x2 - x1) * (yp - y1) - (xp - x1) * (y2 - y1);
    return D >= 0;
  }

  // This function checks if two images overlap
  function checkOverlap(img1, img2) {
    const img1Corners = getImageCorners(
      img1.x,
      img1.y,
      img1.width,
      img1.height,
      img1.rotation
    );
    const img2Corners = getImageCorners(
      img2.x,
      img2.y,
      img2.width,
      img2.height,
      img2.rotation
    );

    // Firstly check if image 1's corners are inside of image 2
    for (let i = 0; i < img1Corners.length; i++) {
      let overlapsCount = 0;
      for (let j = 0; j < img2Corners.length; j++) {
        // If a point is inside of a rectangle (image in our case), it must be in the left hand side of all four edges of the rectangle.
        if (
          !pointInLeftHandSide(
            img2Corners[j].x,
            img2Corners[j].y,
            img2Corners[(j + 1) % 4].x,
            img2Corners[(j + 1) % 4].y,
            img1Corners[i].x,
            img1Corners[i].y
          )
        ) {
          overlapsCount = 0;
          break;
        } else {
          overlapsCount += 1;
        }
      }
      if (overlapsCount === 4) {
        return true;
      }
    }

    // Secondly check if image 2's corners are inside of image 1
    for (let i = 0; i < img2Corners.length; i++) {
      let overlapsCount = 0;
      for (let j = 0; j < img1Corners.length; j++) {
        // If a point is inside of a rectangle (image in our case), it must be in the left hand side of all four edges of the rectangle.
        if (
          !pointInLeftHandSide(
            img1Corners[j].x,
            img1Corners[j].y,
            img1Corners[(j + 1) % 4].x,
            img1Corners[(j + 1) % 4].y,
            img2Corners[i].x,
            img2Corners[i].y
          )
        ) {
          overlapsCount = 0;
          break;
        } else {
          overlapsCount += 1;
        }
      }
      if (overlapsCount === 4) {
        return true;
      }
    }

    return false;
  }

  function isOutsideOfCanvas(cImage, canvasWidth, canvasHeight) {
    const corners = getImageCorners(
      cImage.x,
      cImage.y,
      cImage.width,
      cImage.height,
      cImage.rotation
    );
    return !isInCanvas(corners, canvasWidth, canvasHeight);
  }

  // This function updates the border color of overlapping images
  function updateBorders(
    allCanvasImages,
    canvasWidth = initialWidth,
    canvasHeight = initialHeight
  ) {
    let isOverlap = false;
    for (let i = 0; i < allCanvasImages.length; i++) {
      if (isOutsideOfCanvas(allCanvasImages[i], canvasWidth, canvasHeight)) {
        allCanvasImages[i].showWarningIcon = true;
        allCanvasImages[i].warningIconColor = "purple";
        allCanvasImages[i].stroke = "purple";
        allCanvasImages[i].strokeWidth = 3;
        allCanvasImages[i].dash = [10, 5];
        allCanvasImages[i].dashOffset = allCanvasImages[i].dashOffset || 0;
        isOverlap = true;
      } else {
        if (!allCanvasImages[i].isSvg) {
          const dpi = calculateDPI(
            allCanvasImages[i].initialWidth,
            allCanvasImages[i].initialHeight,
            allCanvasImages[i].width / PPI,
            allCanvasImages[i].height / PPI
          );

          if (dpi.dpiWidth <= 250 || dpi.dpiHeight <= 250) {
            allCanvasImages[i].showWarningIcon = true;
            allCanvasImages[i].warningIconColor = "red";
            allCanvasImages[i].stroke = "red";
            allCanvasImages[i].strokeWidth = 3;
            allCanvasImages[i].dash = [10, 5];
            allCanvasImages[i].dashOffset = allCanvasImages[i].dashOffset || 0;
            isOverlap = true;
          } else if (dpi.dpiWidth < 300 || dpi.dpiHeight < 300) {
            allCanvasImages[i].showWarningIcon = true;
            allCanvasImages[i].warningIconColor = "orange";
            allCanvasImages[i].stroke = "orange";
            allCanvasImages[i].strokeWidth = 3;
            allCanvasImages[i].dash = [10, 5];
            allCanvasImages[i].dashOffset = allCanvasImages[i].dashOffset || 0;
            isOverlap = true;
          } else {
            allCanvasImages[i].showWarningIcon = false;
            allCanvasImages[i].strokeWidth = 0;
          }
        } else {
          allCanvasImages[i].showWarningIcon = false;
          allCanvasImages[i].strokeWidth = 0;
        }
      }
    }

    for (let i = 0; i < allCanvasImages.length; i++) {
      for (let j = i + 1; j < allCanvasImages.length; j++) {
        if (checkOverlap(allCanvasImages[i], allCanvasImages[j])) {
          // If the images overlap, set their border color to blue
          allCanvasImages[i].showWarningIcon = true;
          allCanvasImages[i].warningIconColor = "blue";

          allCanvasImages[j].showWarningIcon = true;
          allCanvasImages[j].warningIconColor = "blue";

          allCanvasImages[i].stroke = "blue";
          allCanvasImages[i].strokeWidth = 3;
          allCanvasImages[i].dash = [10, 5];
          allCanvasImages[i].dashOffset = allCanvasImages[i].dashOffset || 0;

          allCanvasImages[j].stroke = "blue";
          allCanvasImages[j].strokeWidth = 3;
          allCanvasImages[j].dash = [10, 5];
          allCanvasImages[j].dashOffset = allCanvasImages[i].dashOffset || 0;

          isOverlap = true;
        }
      }
    }

    setCanvasImages([...allCanvasImages]);

    if (isOverlap && !requestRef.current) {
      requestRef.current = requestAnimationFrame(animateBorders);
    }
  }

  // Start and safely clean up animation process
  const animateBorders = () => {
    let anyActive = false;

    canvasImagesRef.current.forEach((img) => {
      if (img.showWarningIcon) {
        anyActive = true;
        img.dashOffset -= 0.5;

        // Directly update the Konva node
        const node = img.nodeRef.current;
        if (node) {
          node.dashOffset(img.dashOffset);
        }
      }
    });

    if (anyActive && canvasRef.current) {
      const layerRef = canvasRef.current.getLayerRef();
      if (layerRef.current) {
        layerRef.current.batchDraw();
      }
      requestRef.current = requestAnimationFrame(animateBorders);
    } else {
      cancelAnimationFrame(requestRef.current);
      requestRef.current = null;
    }
  };

  const handleImageDragEnd = () => {
    setUnsavedChanges(true);
    //saveCanvasToDB(canvasImages, images);
  };

  const handleImageTransformEnd = (finalDims) => {
    const initialWidth = finalDims.initialWidth;
    const initialHeight = finalDims.initialHeight;
    const finalWidth = finalDims.finalWidth;
    const finalHeight = finalDims.finalHeight;

    const selectedImage = canvasImages.filter(
      (image) => image.id === selectedImageProps.id
    )[0];

    if (!selectedImage.isSvg) {
      const dpi = calculateDPI(
        selectedImage.initialWidth,
        selectedImage.initialHeight,
        finalWidth / PPI,
        finalHeight / PPI
      );

      const prevDpi = calculateDPI(
        selectedImage.initialWidth,
        selectedImage.initialHeight,
        initialWidth / PPI,
        initialHeight / PPI
      );

      setCurrentImgDPI(dpi.averageDPI);

      if (dpi.averageDPI < 300 && prevDpi.averageDPI > 300) {
        // Do not display the dialog each time. Display it if only it starts exceeding dpi.
        setOpenDPIDialog(true);
      }
    }

    setUnsavedChanges(true);
    //saveCanvasToDB(canvasImages, images);
  };

  const handleDuplicate = (selectedImageId) => {
    const image = canvasImages.filter((item) => item.id === selectedImageId)[0];
    handleImageClick(image, image.width, image.height, image.rotation);
  };

  const updateImagesCount = (cImages) => {
    setImages(
      images.map((item) => ({
        ...item,
        count: cImages.filter((cImage) => cImage.src === item.src).length,
      }))
    );
  };

  const handleImageClick = (image, imageWidth, imageHeight, imageRotation) => {
    saveCanvasState();
    const x = (widthInInches * PPI - imageWidth) / 2;
    const container = document.getElementById("canvasContainer");
    const containerHeight = (container.offsetHeight - imageHeight) / 2;
    const scrollTop = container.scrollTop;
    let y = containerHeight + scrollTop * (1 / stageScale);
    if (
      (containerHeight + scrollTop) * (1 / stageScale) >
      heightInInches * PPI
    ) {
      y = heightInInches * PPI - imageHeight - 10;
    }

    const uploadedImg = images.filter((item) => item.src === image.src)[0];
    if (uploadedImg) {
      uploadedImg.count++;
    }
    const newImage = {
      id: parseInt(Math.random() * 1000000000).toString(),
      src: image.src,
      checksum: image.checksum,
      x: x,
      y: y,
      initialWidth: image.initialWidth ? image.initialWidth : image.width, // If this is the first time that image is created, set its width as initialWidth. However, if the image will be duplicated with this function, use image.initialWidth since image.width could be changed with resizing.
      initialHeight: image.initialHeight ? image.initialHeight : image.height,
      width: imageWidth,
      height: imageHeight,
      rotation: imageRotation,
      showWarningIcon: false,
      warningIconColor: "orange",
      isSvg: image.isSvg,
      nodeRef: React.createRef(),
    };

    const updatedCanvasImages = [...canvasImages, newImage];

    setSelectedImageProps({
      id: newImage.id,
      x: newImage.x,
      y: newImage.y,
      width: newImage.width,
      height: newImage.height,
      rotation: newImage.rotation,
      isSelected: true,
    });

    updateBorders(updatedCanvasImages);

    setUnsavedChanges(true);
    //saveCanvasToDB(updatedCanvasImages, images);
  };

  const scrollBarStyles = {
    customScrollbar: {
      scrollbarColor: "#a0b6e8 rgba(0,0,0,0.1)",
      scrollbarWidth: "thin",
      flexGrow: 1,
      overflow: "auto",
      position: "relative",
    },
  };

  const handleDelete = () => {
    if (selectedImageProps.id !== null) {
      const remainingImages = canvasImages.filter(
        (image) => image.id !== selectedImageProps.id
      );
      handleImagesChange(remainingImages);

      setSelectedImageProps((prevProps) => ({
        ...prevProps,
        id: null,
        isSelected: false,
      }));

      setUnsavedChanges(true);
      //saveCanvasToDB(remainingImages, images);
    }
  };

  const handleAutoFill = (selectedImageId) => {
    saveCanvasState();
    const image = canvasImages.filter((item) => item.id === selectedImageId)[0];

    let cImages = [...canvasImages]; // Clone the array to avoid direct mutation

    let counter = 0;
    let imgX = image.x;
    let imgY = image.y;
    while (counter < 20) {
      // Call findFirstAvailableSpace for each iteration
      let result = findFirstAvailableSpace(
        initialWidth,
        initialHeight,
        image.width,
        image.height,
        imgX,
        imgY,
        imgMargin * PPI,
        image.rotation
      );

      if (result.x && result.y) {
        counter++;
        cImages.push({
          id: parseInt(Math.random() * 1000000000).toString(),
          src: image.src,
          checksum: image.checksum,
          x: result.x,
          y: result.y,
          initialWidth: image.initialWidth,
          initialHeight: image.initialHeight,
          width: image.width,
          height: image.height,
          rotation: image.rotation,
          showWarningIcon: false,
          warningIconColor: "orange",
          isSvg: image.isSvg,
          nodeRef: React.createRef(),
        });

        imgX = result.x;
        imgY = result.y;
      } else {
        break;
      }
    }

    setCanvasImages(cImages);
    images.filter((item) => item.src === image.src)[0].count += counter;
    updateBorders(cImages);

    setUnsavedChanges(true);
    //saveCanvasToDB(cImages, images);
  };

  const findFirstAvailableSpace = (
    canvasWidth,
    canvasHeight,
    imgWidth,
    imgHeight,
    imgX,
    imgY,
    margin,
    rotationDegree
  ) => {
    const getRotatedRectSize = (width, height, rotationDegrees) => {
      const radians = (Math.PI / 180) * rotationDegrees;

      const cos = Math.abs(Math.cos(radians));
      const sin = Math.abs(Math.sin(radians));
      return {
        width: width * cos + height * sin,
        height: width * sin + height * cos,
      };
    };

    // Calculate the size of the bounding box for the rotated image
    const rotatedSize = getRotatedRectSize(imgWidth, imgHeight, rotationDegree);
    const effectiveWidth = rotatedSize.width;
    const effectiveHeight = rotatedSize.height;

    let xPos = imgX + effectiveWidth + margin;
    for (let y = imgY; y <= canvasHeight - 10; y += effectiveHeight + margin) {
      for (let x = xPos; x <= canvasWidth - 10; x += effectiveWidth + margin) {
        const corners = getImageCorners(
          x,
          y,
          imgWidth,
          imgHeight,
          rotationDegree
        );

        if (isInCanvas(corners, canvasWidth, canvasHeight)) {
          let foundSpace = { x, y };
          return { ...foundSpace };
        }
      }
      xPos = 10;
    }

    return { x: null, y: null };
  };

  const isInCanvas = (corners, canvasWidth, canvasHeight) => {
    return corners.every((corner) => {
      return (
        corner.x > 10 &&
        corner.y > 10 &&
        corner.x < canvasWidth - 10 &&
        corner.y < canvasHeight - 10
      );
    });
  };

  const handleSendCanvas = () => {
    setOpenLoadingDialog(true);
    setUnsavedChanges(false);

    setTimeout(() => {
      handleRemoveBackground();
      setTimeout(() => {
        const url = canvasRef.current.getStageDataURL();

        const pdf = new jsPDF({
          orientation:
            widthInInches > heightInInches ? "landscape" : "portrait",
          unit: "in",
          format: [widthInInches, heightInInches],
        });

        pdf.addImage(url, "PNG", 0, 0, widthInInches, heightInInches);

        pdf.save("canvas.pdf");
        window.location.reload();
      }, 1500);
    }, 1500);
  };

  const handleClearCanvas = () => {
    handleImagesChange([]);

    setSelectedImageProps((prevProps) => ({
      ...prevProps,
      id: null,
      isSelected: false,
    }));

    setUnsavedChanges(true);
    //saveCanvasToDB([], images);
  };

  const handleUploadedImageDelete = (imageSrc) => {
    saveCanvasState();
    const remainingImages = images.filter((image) => image.src !== imageSrc);
    setImages(remainingImages);

    setUnsavedChanges(true);
    //saveCanvasToDB(canvasImages, remainingImages);
  };

  const handleUploadedImageSizeChange = (imageSrc) => {
    const uploadedImg = images.filter((image) => image.src === imageSrc)[0];
    setImgManualHeight((uploadedImg.printHeight / PPI).toFixed(2));
    setImgManualWidth((uploadedImg.printWidth / PPI).toFixed(2));
  };

  const handleImageSizeSave = () => {
    saveCanvasState();
    const currentImg = canvasImages.filter(
      (image) => image.id === selectedImageProps.id
    )[0];

    if (currentImg) {
      const updatedImages = canvasImages.map((img) =>
        img.id === currentImg.id
          ? {
              ...img,
              width: imgManualWidth * PPI,
              height: imgManualHeight * PPI,
            }
          : img
      );

      selectedImageProps.height = imgManualHeight * PPI;
      selectedImageProps.width = imgManualWidth * PPI;

      setSelectedImageProps(selectedImageProps);
      updateBorders(updatedImages);

      setUnsavedChanges(true);
      //saveCanvasToDB(updatedImages, images);
    }
  };

  return notFound ? (
    <NotFoundPage />
  ) : (
    <>
      <Modal open={openLoadingDialog}>
        <ModalDialog variant="outlined" layout="fullscreen">
          <DialogContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <CircularProgress size={30} /> Loading...
          </DialogContent>
        </ModalDialog>
      </Modal>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSaveSnackbar}
        color={"success"}
        onClose={() => setOpenSaveSnackbar(false)}
        key={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setOpenSaveSnackbar(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Your canvas has been saved successfully!
        </Alert>
      </Snackbar>

      <Dialog
        open={openDPIDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDPIDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"DPI Alert!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This image has a lower resolution than recommended. The final
            product may be blurry. Please consider using an image with a higher
            resolution. Current DPI: {currentImgDPI.toFixed(2)}, recommended:
            300, minimal: 250
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              textTransform: "capitalize",
              fontWeight: "bold",
              borderRadius: "10px",
              padding: "10px",
              marginLeft: "20px",
              background: "red",
              color: "white",
            }}
            onClick={handleDPIDialogClose}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <SideBar
        open={openSideBar}
        setOpen={setOpenSideBar}
        canvasImages={canvasImages}
        uploadedImages={images}
        queryParams={queryParams}
        updateUnsavedChanges={handleUnsavedChange}
      />

      <div
        style={{
          display: "flex",
          width: "100vw",
          height: "100vh",
          maxHeight: "100vh",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "25vw",
            height: "100vh",
            borderRight: "5px solid #e1e8e8",
            padding: "40px",
            position: "relative",
            boxSizing: "border-box",
            overflowY: "auto",
            scrollbarColor: "#a0b6e8 rgba(0,0,0,0.1)",
            scrollbarWidth: "thin",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Box
              component="img"
              sx={{
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                marginBottom: "20px"
              }}
              alt="Inteex"
              src={logo}
            />

            <div
              style={{
                display: selectedImageProps.id ? "flex" : "none",
                flexDirection: "column",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Text style={{ fontSize: "14px" }}>Width (in):</Text>
                <TextField
                  variant="outlined"
                  placeholder="Width"
                  type="number"
                  style={{ width: "100px" }}
                  inputProps={{
                    style: { padding: "6px", fontSize: "14px" },
                  }}
                  value={imgManualWidth}
                  onChange={(e) => {
                    const currentImage = canvasImages.filter(
                      (item) => item.id === selectedImageProps.id
                    )[0];
                    const ratio =
                      currentImage.initialWidth / currentImage.initialHeight;
                    if (
                      parseFloat(e.target.value) > 0 &&
                      parseFloat(e.target.value) / ratio > 0
                    ) {
                      setImgManualWidth(parseFloat(e.target.value));
                      setImgManualHeight(
                        (parseFloat(e.target.value) / ratio).toFixed(2)
                      );
                    }
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Text style={{ fontSize: "14px" }}>Height (in):</Text>
                <TextField
                  variant="outlined"
                  placeholder="Height"
                  type="number"
                  style={{ width: "100px" }}
                  inputProps={{
                    style: { padding: "6px", fontSize: "14px" },
                  }}
                  value={imgManualHeight}
                  onChange={(e) => {
                    const currentImage = canvasImages.filter(
                      (item) => item.id === selectedImageProps.id
                    )[0];
                    const ratio =
                      currentImage.initialHeight / currentImage.initialWidth;
                    if (
                      parseFloat(e.target.value) > 0 &&
                      parseFloat(e.target.value) / ratio > 0
                    ) {
                      setImgManualHeight(parseFloat(e.target.value));
                      setImgManualWidth(
                        (parseFloat(e.target.value) / ratio).toFixed(2)
                      );
                    }
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <Button
                  component="label"
                  variant="contained"
                  style={{
                    borderRadius: "10px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    marginTop: "10px",
                    fontSize: "12px",
                  }}
                  onClick={handleImageSizeSave}
                >
                  Update
                </Button>
              </div>
            </div>

            {designStatus !== "completed" && (
              <Button
                component="label"
                variant="contained"
                style={{
                  borderRadius: "10px",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                  marginTop: "10px",
                }}
              >
                <FileUploadIcon />
                Upload File
                <VisuallyHiddenInput
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/svg+xml"
                  onChange={handleFileUpload}
                  multiple
                />
              </Button>
            )}

            <Text
              style={{
                marginTop: "20px",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              Maximum File Limit: <b>100MB.</b> Supported File Types:{" "}
              <b>png, jpeg, jpg, svg</b>
            </Text>
          </div>

          {designStatus !== "completed" && (
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Text style={{ fontSize: "14px" }}>Margin: (in)</Text>
              <TextField
                variant="outlined"
                placeholder="Margin"
                type="number"
                inputProps={{
                  style: { padding: "6px", fontSize: "14px" },
                }}
                style={{ width: "100px" }}
                value={imgMargin}
                onChange={(e) => {
                  if (e.target.value > 0) {
                    setImgMargin(parseFloat(e.target.value));
                  }
                }}
              />
            </div>
          )}

          <div
            style={{
              display: images.length === 0 ? "none" : "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Text style={{ fontWeight: "bold" }}>Uploaded Files</Text>
          </div>

          <div style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
            {images.map((image, index) => (
              <div
                key={image.fileId}
                style={{
                  border: "3px solid lightblue",
                  borderRadius: "5px",
                  height: "100px",
                  width: "100px",
                  margin: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: designStatus === "completed" ? "default" : "pointer",
                  position: "relative",
                }}
                onClick={() => {
                  if (designStatus !== "completed") {
                    if (image.loaded) {
                      handleUploadedImageSizeChange(image.src);
                      handleImageClick(
                        image,
                        image.printWidth,
                        image.printHeight,
                        0
                      );
                    }
                  }
                }}
              >
                {!image.loaded && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
                {image.loaded && (
                  <>
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        backgroundColor: "green",
                        borderRadius: "50%",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        fontSize: "12px",
                      }}
                    >
                      {image.count}
                    </div>
                    <img
                      key={index}
                      src={image.src}
                      alt=""
                      style={{
                        maxWidth: "90px",
                        maxHeight: "90px",
                      }}
                    />

                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                        zIndex: 100,
                      }}
                    >
                      {designStatus !== "completed" && (
                        <IconButton
                          style={{
                            margin: 0,
                            padding: 0,
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUploadedImageDelete(image.src);
                          }}
                        >
                          <DeleteIcon
                            style={{ fontSize: "20px", color: "red" }}
                          />
                        </IconButton>
                      )}
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: "75vw",
          }}
        >
          <div
            style={{
              display: "flex",
              borderBottom: "5px solid #e1e8e8",
              background: "#f2f5f5",
              justifyContent: "space-between",
              height: "10vh",
              alignItems: "center",
            }}
          >
            {designStatus !== "completed" && (
              <div>
                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                  onClick={handleUndo}
                  disabled={undoStack.length === 0}
                >
                  <UndoIcon style={{ marginRight: "4px" }} />
                  Undo
                </Button>

                <Button
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                  }}
                  onClick={handleRedo}
                  disabled={redoStack.length === 0}
                >
                  <RedoIcon style={{ marginRight: "4px" }} />
                  Redo
                </Button>
              </div>
            )}
            <div
              style={{
                display: "flex",
                borderRight: "10px solid #e1e8e8",
                alignItems: "center",
                visibility: selectedImageProps.isSelected
                  ? "visible"
                  : "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  margin: "20px",
                  fontSize: "14px",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text style={{ marginRight: "20px" }}>
                    Width: {(selectedImageProps.width / PPI).toFixed(2)}
                    <b style={{ marginLeft: "5px" }}>in</b>
                  </Text>
                  <Text style={{ marginRight: "20px" }}>
                    Height: {(selectedImageProps.height / PPI).toFixed(2)}
                    <b style={{ marginLeft: "5px" }}>in</b>
                  </Text>
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Text style={{ marginRight: "20px" }}>
                    X: {(selectedImageProps.x / PPI).toFixed(2)}
                    <b style={{ marginLeft: "5px" }}>in</b>
                  </Text>
                  <Text style={{ marginRight: "20px" }}>
                    Y: {(selectedImageProps.y / PPI).toFixed(2)}
                    <b style={{ marginLeft: "5px" }}>in</b>
                  </Text>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <Text style={{ marginRight: "20px" }}>
                    Rotation: {selectedImageProps.rotation.toFixed(2)}
                  </Text>
                </div>
              </div>
            </div>

            {designStatus !== "completed" && (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  boxSizing: "border-box",
                  visibility: selectedImageProps.isSelected
                    ? "visible"
                    : "hidden",
                }}
              >
                <Button
                  component="label"
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    padding: "8px",
                    fontSize: "13px",
                  }}
                  variant="contained"
                  onClick={() => handleDuplicate(selectedImageProps.id)}
                >
                  <ContentCopyIcon
                    style={{ marginRight: "5px", width: "15px" }}
                  />
                  Duplicate
                </Button>

                <Button
                  component="label"
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    marginLeft: "15px",
                    padding: "8px",
                    fontSize: "13px",
                  }}
                  variant="contained"
                  onClick={() => handleAutoFill(selectedImageProps.id)}
                >
                  <AutoAwesomeIcon
                    style={{ marginRight: "5px", width: "15px" }}
                  />
                  Auto Fill
                </Button>

                <Button
                  component="label"
                  style={{
                    textTransform: "capitalize",
                    fontWeight: "bold",
                    borderRadius: "10px",
                    marginLeft: "15px",
                    background: "red",
                    padding: "8px",
                    fontSize: "13px",
                  }}
                  variant="contained"
                  onClick={() => handleDelete()}
                >
                  <DeleteOutlineIcon
                    style={{ marginRight: "5px", width: "15px" }}
                  />
                  Remove
                </Button>
              </div>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                borderLeft: "10px solid #e1e8e8",
                height: "100%",
                boxSizing: "border-box",
                marginRight: "20px",
              }}
            >
              {designStatus !== "completed" && (
                <>
                  <Button
                    component="label"
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      marginLeft: "15px",
                      background: "red",
                      padding: "8px",
                      fontSize: "13px",
                    }}
                    variant="contained"
                    onClick={() => handleClearCanvas()}
                  >
                    <ClearIcon style={{ marginRight: "5px", width: "15px" }} />
                    Clear
                  </Button>

                  <Button
                    component="label"
                    style={{
                      textTransform: "capitalize",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      marginLeft: "15px",
                      padding: "8px",
                      fontSize: "13px",
                    }}
                    variant="contained"
                    disabled={saveButtonLoading}
                    onClick={() => saveCanvasToDB(canvasImages, images)}
                  >
                    <SaveIcon style={{ marginRight: "5px", width: "15px" }} />
                    {!saveButtonLoading && "Save"}
                    {saveButtonLoading && (
                      <CircularProgress
                        size={15}
                        style={{ marginRight: "5px" }}
                      />
                    )}
                  </Button>
                </>
              )}
              <Button
                component="label"
                style={{
                  textTransform: "capitalize",
                  fontWeight: "bold",
                  borderRadius: "10px",
                  marginLeft: "15px",
                  background: "green",
                  padding: "8px",
                  fontSize: "13px",
                }}
                variant="contained"
                onClick={() =>
                  designStatus === "completed"
                    ? handleSendCanvas()
                    : setOpenSideBar(true)
                }
              >
                <CheckCircleIcon
                  style={{ marginRight: "5px", width: "15px" }}
                />
                {designStatus === "completed" ? "Download" : "Confirm"}
              </Button>
            </div>
          </div>

          <div style={scrollBarStyles.customScrollbar} id="canvasContainer">
            <div
              style={{
                background: "#a0b6e8",
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: initialWidth,
              }}
            >
              <CanvasHorizontalRuler
                PPI={PPI}
                scale={stageScale}
                stageXPos={stagePositions.x}
                widthInInches={widthInInches}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                position: "relative",
                width: initialWidth,
              }}
            >
              <div
                style={{
                  background: "#a0b6e8",
                  position: "sticky",
                  left: 0,
                  display: "flex",
                  flexDirection: "column",
                  height: initialHeight,
                  zIndex: 10,
                }}
              >
                <CanvasVerticalRuler
                  PPI={PPI}
                  scale={stageScale}
                  stageYPos={stagePositions.y}
                  widthInInches={heightInInches}
                />
              </div>

              {!isLoading && (
                <KonvaCanvas
                  ref={canvasRef}
                  designStatus={designStatus}
                  scale={stageScale}
                  width={initialWidth}
                  height={initialHeight}
                  images={canvasImages}
                  onSelectedImagePropsChange={handleSelectedImagePropsChange}
                  onImagesChange={handleImagesChange}
                  onStagePosChange={handleStagePosChange}
                  onImageDelete={handleDelete}
                  canvasHasBackgrounds={canvasHasBackgrounds}
                  onImageTransformEnd={handleImageTransformEnd}
                  onImageDragEnd={handleImageDragEnd}
                  selectedImageProps={selectedImageProps}
                  onCanvasChange={saveCanvasState}
                />
              )}
            </div>

            {designStatus !== "completed" && (
              <div
                style={{
                  display: canvasHasBackgrounds ? "block" : "none",
                  position: "fixed",
                  bottom: "30px",
                  right: "30px",
                  height: "30px",
                }}
              >
                <button
                  onClick={zoomOut}
                  style={{
                    backgroundColor: "#1976D2",
                    borderRadius: "5px",
                    color: "white",
                    border: "none",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    fontSize: "20px",
                    marginRight: "5px"
                  }}
                >
                  -
                </button>
                <button
                  onClick={zoomIn}
                  disabled={zoomInDisabled}
                  style={{
                    backgroundColor: "#1976D2",
                    borderRadius: "5px",
                    color: "white",
                    border: "none",
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                    fontSize: "20px",
                  }}
                >
                  +
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const calculateScaledDimensions = (originalWidth, originalHeight, scale) => {
  return {
    scaledWidth: originalWidth * scale,
    scaledHeight: originalHeight * scale,
  };
};

export default App;
